import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Pressable,
  Alert,
  Platform  // Import platform for detecting the current platform
} from 'react-native';
import { getDoc, doc, collection, getDocs, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";

import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';

// Set notification handler to define behavior when a notification is received
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

// Function to request notification permission on the web and send a notification
const sendWebNotification = (title, body) => {
  if (!('Notification' in window)) {
    Alert.alert('Web Notification', 'This browser does not support notifications.');
    return;
  }

  // Check if the user has already granted permission
  if (Notification.permission === 'granted') {
    new Notification(title, { body });
  } else if (Notification.permission !== 'denied') {
    // Request permission from the user
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        new Notification(title, { body });
      }
    });
  }
};

const PlayersInfo = ({ route }) => {
  const [gameDates, setGameDates] = useState([]);
  const [playerGameData, setPlayerGameData] = useState([]);
  const database = getFirestore();
  const currentMainGameId = route.params.mainGameId.toString();  // Get MainGameId from route

  // Fetch game data and players
  useEffect(() => {
    const fetchGameDataAndPlayers = async () => {
      try {
        // 1. Get the MainGame document (to fetch memberOfGame)
        const mainGameDocRef = doc(database, 'MainGame', currentMainGameId);
        const mainGameSnapshot = await getDoc(mainGameDocRef);

        if (mainGameSnapshot.exists()) {
          const mainGameData = mainGameSnapshot.data();

          // Get the memberOfGame (list of players)
          const players = mainGameData.memberOfGame || [];

          // 2. Fetch the 'TheGame' sub-collection to get game details (date, time)
          const gamesCollectionRef = collection(database, 'MainGame', currentMainGameId, 'TheGame');
          const gamesSnapshot = await getDocs(gamesCollectionRef);

          let combinedData = [];
          let gameDatesWithTime = [];

          // Iterate over each game and its players
          await Promise.all(gamesSnapshot.docs.map(async (gameDoc) => {
            const gameData = gameDoc.data();
            const gameDate = gameData.date;
            const gameTime = gameData.time;

            // Store unique game dates along with times
            if (!gameDatesWithTime.some(game => game.date === gameDate && game.time === gameTime)) {
              gameDatesWithTime.push({ date: gameDate, time: gameTime });
            }

            // Combine players with date, time, and fetch expoPushToken from users collection
            await Promise.all(players.map(async (player) => {
              let expoPushToken = 'No Token';  // Default value if no token is found

              // Fetch user data from 'users' collection using the player's UID
              if (player.uid) {
                const userDocRef = doc(database, 'users', player.uid);
                const userSnapshot = await getDoc(userDocRef);

                // Check if user document exists and get the expoPushToken
                if (userSnapshot.exists()) {
                  const userData = userSnapshot.data();
                  expoPushToken = userData.expoPushToken || 'No Token';
                }
              }

              // Check if the player is already in combinedData (to avoid duplicates)
              const isPlayerAlreadyInList = combinedData.some(item => item.uid === player.uid);

              if (!isPlayerAlreadyInList) {
                // Add player data only if they are not already in the list
                combinedData.push({
                  uid: player.uid,
                  name: player.name,
                  email: player.email || 'No Email',  // Fallback if email is missing
                  expoPushToken: expoPushToken,
                });
              }
            }));
          }));

          setGameDates(gameDatesWithTime);  // Store unique game dates with time
          setPlayerGameData(combinedData);  // Store combined player data (with no duplicates)
        } else {
          console.log("Main game document not found.");
        }
      } catch (error) {
        console.error("Error fetching game data and players: ", error);
      }
    };

    fetchGameDataAndPlayers();
  }, [currentMainGameId]);

// Function to send notification to a player using Firebase Cloud Function
const sendNotificationToPlayer = async (expoPushToken) => {
  if (expoPushToken === 'No Token') {
    Alert.alert('Notification Failed', 'This player does not have a valid Expo Push Token.');
    return;
  }

  try {
    const functions = getFunctions();  // Initialize Firebase Functions
    const sendNotification = httpsCallable(functions, 'sendNotification');

    // Call the Firebase Cloud Function
    const result = await sendNotification({
      expoPushToken: expoPushToken,
      title: 'Mobile Notification',
      body: 'Hello! You have a new message!',
    });

    // Display success message
    Alert.alert('Notification Sent', `Notification sent to player with token: ${expoPushToken}`);
  } catch (error) {
    console.error('Error sending notification via Firebase Function:', error);
    Alert.alert('Notification Error', 'Failed to send notification.');
  }
};
  


  // Render each game date with time
  const renderGameDate = (item, index) => (
    <Text style={styles.dateText} key={index}>
      Date: {item.date}, Time: {item.time}
    </Text>
  );

  // Render each player with name, email, expoPushToken, and pressable button to send notification
  const renderPlayerGameItem = ({ item }) => (
    <View style={styles.item}>
      <Text style={styles.text}>{item.name} - {item.email} - Token: {item.expoPushToken}</Text>
      <Pressable
        style={styles.button}
        onPress={() => sendNotificationToPlayer(item.expoPushToken)}
      >
        <Text style={styles.buttonText}>Send Notification</Text>
      </Pressable>
    </View>
  );

  return (
    <View style={styles.container}>
      {/* Display Game Dates Above the Players List */}
      <View style={styles.gameDatesContainer}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>Game Dates</Text>
        {gameDates.map((date, index) => renderGameDate(date, index))}
      </View>

      {/* Display Players List */}
      <View style={styles.playersContainer}>
        <Text style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 10 }}>Players</Text>
        <FlatList
          data={playerGameData}
          renderItem={renderPlayerGameItem}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#FFFFFF',
  },
  gameDatesContainer: {
    marginBottom: 20,  // Add margin between game dates and players
  },
  dateText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  playersContainer: {
    flex: 1,
  },
  item: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    flexDirection: 'row',  // Arrange in a row
    justifyContent: 'space-between',  // Space between text and button
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    flex: 1,  // Allow the text to take available space
  },
  button: {
    backgroundColor: '#4CAF50',
    padding: 10,
    marginLeft: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default PlayersInfo;

// navigation changed.

// 3 navigation. 
import Profilex from "../screens/Profilex";

import { UserProvider } from './UserContext';

import { MaterialIcons } from '@expo/vector-icons';



import { View, Text, TouchableOpacity, SafeAreaView } from "react-native";
import React, { useEffect, useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { Avatar } from "react-native-elements";
import { doc, setDoc, updateDoc, increment, serverTimestamp } from 'firebase/firestore';
import { database, auth } from "../componenets/firebase";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import CustomNavigationDrawer from "../componenets/CustomNavigationDrawer";
//import { DrawerContentScrollView, DrawerItemList, } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import Ionicons from "react-native-vector-icons/Ionicons";

// Screens imports:
import MyGames from "../screens/MyGames";
import TheGame from "../screens/TheGame";
import Players from "../screens/Players";
import Results from "../screens/Results";
import MakeGame from "../screens/MakeGame";
import MakeDates from "../screens/MakeDates";
// import ResaultScreen from "../screens/ResaultScreen"; // Taka út!!!
import ScoreCard from "../screens/ScoreCard";
// import Testleaderboard from "../screens/Testleaderboard";
import LeaderBoard from "../screens/LeaderBoard";
//import LeaderBoardTest from "../screens/LeaderBoardTest";
import AddPlayerScreen from "../screens/AddPlayerScreen";
import GameSettings from "../screens/GameSettings";
import Sandbox from "../screens/Sandbox";
import About from "../screens/About";
import GameDashboard from "../screens/GameDashboard";
import BeerCountLeaderboard from "../screens/BeerCountLeaderboard";
import LeagueTable from "../screens/LeagueTable";
import TabLeaderBoard from "../screens/TabLeaderBoard";
import BeerCounter from "../screens/BeerCounter";
import Pvp from "../screens/Pvp";
import PvpLeaderBoard from "../screens/PvpLeaderBoard";
import PvpLeaderScore from "../screens/PvpLeaderScore";
import KnockOut from "../screens/KnockOut";
import KnockOutResults from "../screens/KnockOutResults";
import Chat from "../screens/Chat";
import Bug from "../screens/Bug";
import Users from "../screens/UserList";
import GameList from "../screens/GameList";
import AccountSettings from "../screens/AccountSettings";
import PlayersInfo from "../screens/PlayersInfo";
import Notification from "../screens/Notification";

import { useUser } from './UserContext';

// token stuff
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device'; // Import Device to check if you're on a physical device
import { Platform } from 'react-native'; // Import Platform to check the running platform





//import DeleteConfirm from "../screens/DeleteConfirm";

//import DeleteAccount from "../screens/Deleteaccount";

import { getAuth, onAuthStateChanged } from "firebase/auth";

const Stack = createNativeStackNavigator();


// avatar 
// HeaderRigt avatar and name


// const HeaderRight = ({ navigation }) => {
//   const [userName, setUserName] = useState("");
//   const [avatarUri, setAvatarUri] = useState("");
//   const [loading, setLoading] = useState(true);  // Loading state
//   const { globalUserId } = useUser(); // Access globalUserId from context

//   useEffect(() => {
//     console.log("Received globalUserId in HeaderRight:", globalUserId); 

//     if (globalUserId) {
//       getUserInfo(globalUserId); 
//     }
//   }, [globalUserId]);


//   async function getUserInfo(userId) {
//     try {
//       const userRef = doc(database, "users", userId);
//       const userSnapshot = await getDoc(userRef);

//       if (userSnapshot.exists()) {
//         const userData = userSnapshot.data();
//         console.log("Fetched user data from Firestore:", userData);

//         setUserName(userData.name || "No Name"); 
//         setAvatarUri(userData.avatarUri || "No Avatar URI"); 
//       } else {
//         console.log("No such user found!");
//         setUserName("No Name");
//         setAvatarUri("No Avatar URI");
//       }
//     } catch (error) {
//       console.error("Error fetching user info:", error);
//     } finally {
//       setLoading(false);  // Ensure loading state is set to false after fetching
//     }
//   }

//   if (loading) {
//     return (
//       <View style={{ marginLeft: 10 }}>
//         <Text style={{ color: "white", alignSelf: "flex-end", paddingRight: 10 }}>
//           Loading...
//         </Text>
//       </View>
//     );
//   }

//   return (
//     <View style={{ marginLeft: 10 }}>
//       <Avatar
//         rounded
//         size="medium"
//         source={avatarUri && avatarUri !== "No Avatar URI" ? { uri: avatarUri } : null} 
//         title={avatarUri === "No Avatar URI" ? userName.slice(0, 1).toUpperCase() : null} 
//         overlayContainerStyle={{ backgroundColor: "#bcbec1" }}
//         onPress={() => navigation.openDrawer()}
//         activeOpacity={0.7}
//       />
//       <Text style={{ color: "white", alignSelf: "flex-end", paddingRight: 10 }}>
//           xx {userName !== "No Name" ? userName : "Loading..."}
//       </Text>
//     </View>
//   );
// };






const HeaderRight = ({ navigation }) => {
 
  

  return (
    <View style={{ marginLeft: 10 }}>
      <Avatar
        rounded
        size="medium"
        source={require('../assets/Profile2.png')}  // Use static image from assets
        onPress={() => navigation.openDrawer()}
        activeOpacity={0.7}
      />
      <Text style={{ color: "white", alignSelf: "flex-end", paddingRight: 10 }}>
        Menu
      </Text>
    </View>
  );
};





const StackNavigation = () => (
  <Stack.Navigator
    screenOptions={({ navigation }) => ({
      headerStyle: { backgroundColor: "#4361ee" },
      headerTintColor: "white",
      headerTitleStyle: { fontWeight: "bold" },
      headerTitleAlign: "center",
      headerBackTitleVisible: true,
      //headerRight: () => <HeaderRight navigation={navigation} />,
      headerRight: () => <HeaderRight navigation={navigation} />, // HeaderRight will rerender on global state update
    })}
  >
    <Stack.Screen
      name="My Games"
      component={MyGames}
      options={{ headerShown: true, headerBackVisible: true, headerBackTitleVisible: true }}
    />

    <Stack.Screen
      name="Game Date"
      component={TheGame}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="MakeDates"
      component={MakeDates}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Make Game"
      component={MakeGame}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="BeerCounter"
      component={BeerCounter}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="Pvp"
      component={Pvp}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="PvpLeaderBoard"
      component={PvpLeaderBoard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="PvpLeaderScore"
      component={PvpLeaderScore}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="KnockOut"
      component={KnockOut}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="KnockOutResults"
      component={KnockOutResults}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Chat"
      component={Chat}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    /> 

    <Stack.Screen
      name="AddPlayerScreen"
      component={AddPlayerScreen}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="GameSettings"
      component={GameSettings}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="Players"
      component={Players}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />
    <Stack.Screen
      name="Results"
      component={Results}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="LeaderBoard"
      component={LeaderBoard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="GameDashboard"
      component={GameDashboard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="BeerCountLeaderboard"
      component={BeerCountLeaderboard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="LeagueTable"
      component={LeagueTable}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="ScoreCard"
      component={ScoreCard}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen
      name="AccountSettings"
      component={AccountSettings}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

    <Stack.Screen      
      name="PlayersInfo"
      component={PlayersInfo}
      options={{
        headerShown: true,
        headerBackVisible: true,
        headerBackTitleVisible: false,
      }}
    />

  </Stack.Navigator>
);

function AnotherScreen1() {
  const navigation = useNavigation();

  return (
    <View>
      <Text>Another Navigation! </Text>
      <TouchableOpacity onPress={() => navigation.navigate("Profile")}>
        <Text>Drawer - Go to Profile </Text>
      </TouchableOpacity>
    </View>
  );
}

function DrawerProfile() {
  const navigation = useNavigation();

  return (
    <SafeAreaView>
      <View>
        <Text>Profile screen ! </Text>
        <TouchableOpacity onPress={() => navigation.navigate("Another")}>
          <Text>Drawer - Go to Another Screen </Text>
        </TouchableOpacity>
      </View>
    </SafeAreaView>
  );
}

const Drawer = createDrawerNavigator();

const DrawerNavigation = ({ userIdTop }) => (
  <Drawer.Navigator
    screenOptions={{ headerShown: false }}
    drawerContent={(navProps) => <CustomNavigationDrawer {...navProps} />}
  >
    <Drawer.Screen
      name="DrawerNavigation"
      options={{ drawerItemStyle: { display: "none" } }}
    >
      {props => <StackNavigation {...props} userIdTop={userIdTop} />}
    </Drawer.Screen>
    <Drawer.Screen name="Profile" component={Profilex} />
    {/* ... other Drawer.Screen components */}
  </Drawer.Navigator>
);

const Tab = createBottomTabNavigator();



const TabNavigator = ({ userIdTop }) => {
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setUserEmail(user ? user.email : null);
    });
    return unsubscribe;
  }, []);

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarIcon: ({ focused, color, size }) => {
          let iconName;
          switch (route.name) {
            case 'My Games': iconName = 'fort'; break;
            case 'Profile': iconName = 'person'; break;
            case 'About': iconName = 'info'; break;
            case 'Bug': iconName = 'bug-report'; break;
            case 'Users': iconName = 'group'; break;
            case 'GameList': iconName = 'games'; break;
            case 'Notifications': iconName = 'info'; break;
            default: iconName = 'circle'; break;
          }
          return <MaterialIcons name={iconName} size={size} color={color} />;
        },
        tabBarActiveTintColor: 'dodgerblue',
        tabBarInactiveTintColor: 'gray',
      })}
    >
      <Tab.Screen name="My Games">
        {props => <DrawerNavigation {...props} userIdTop={userIdTop} />}
    </Tab.Screen>
    <Tab.Screen name="Profile" component={Profilex} />
    <Tab.Screen name="About" component={About} />
    <Tab.Screen name="Bug" component={Bug} />

    {userEmail === "dondanni@hotmail.com" && <Tab.Screen name="Notification" component={Notification} />}



    {/* <Tab.Screen name="Notification" component={Notification} /> */}
    { (userEmail === "dondanni@hotmail.com" || userEmail === "pathfix@test.com") && (  <Tab.Screen name="Users" component={Users} />)}
    {userEmail === "dondanni@hotmail.com" && <Tab.Screen name="GameList" component={GameList} />}
  </Tab.Navigator>
);
};

export default function Navigation({ navigationRef, routeNameRef, onStateChange }) {
  const [userIdTop, setUserIdTop] = useState(null); // State to store the userId

  // Function to save token and increment login count
  const updateUserTokenAndLoginCount = async (currentUser) => {
    if (currentUser) {
      const userId = currentUser.uid;
      console.log("Current User ID:", userId);
  
      // Create a reference to the user's document
      const userRef = doc(database, "users", userId);
  
      try {
        // Check if the platform is not web before requesting the Expo push token
        if (Platform.OS === 'web') {
          console.log('Push notifications are skipped on web.');
        } else {
          // Get the Expo push token for native platforms (iOS/Android)
          const token2 = (await Notifications.getExpoPushTokenAsync()).data;
          console.log('Expo Push Token:', token2);
  
          // Update the user document with the token
          await setDoc(userRef, { expoPushToken: token2, lastLogin: serverTimestamp() }, { merge: true });
          console.log('Token and last login saved to Firestore.');
  
          // Increment the login count
          await updateDoc(userRef, {
            loginCount: increment(1)
          });
          console.log('Login count incremented.');
        }
      } catch (error) {
        console.error('Error updating token and login count:', error);
      }
    } else {
      console.log("No user is signed in");
    }
  };

  useEffect(() => {
    const auth = getAuth(); // Get the auth instance
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userId = currentUser.uid;
      setUserIdTop(userId); // Store userId in state
      console.log("Current User ID:", userId); // Log the UID

      // Now call the function to update the token and login count once userIdTop is set
      updateUserTokenAndLoginCount(currentUser); // Pass the currentUser to the function
    } else {
      console.log("No user is signed in");
    }
  }, []);

  if (!userIdTop) {
    return null; // or a loading spinner component until userIdTop is available
  }


  return (
    <UserProvider>
      <NavigationContainer
        ref={navigationRef}
        onReady={() => { routeNameRef.current = navigationRef.current.getCurrentRoute().name;  }}
        onStateChange={onStateChange}>
        <TabNavigator userIdTop={userIdTop} /> 
      </NavigationContainer>
    </UserProvider>
  );
}

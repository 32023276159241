import React, { useState } from 'react';
import { View, Text, Button, Alert } from 'react-native';

const Notification = () => {
  const [loading, setLoading] = useState(false);

  // Function to trigger the push notification by calling the Firebase Cloud Function
  const sendNotification = async () => {
    setLoading(true);
    try {
      // Replace with your Firebase Cloud Function URL
      //const functionUrl = 'https://us-central1-quizapp-784c4.cloudfunctions.net/sendPushNotification';
      const functionUrl = 'https://sendpushnotification2-jcaoixxixq-uc.a.run.app/';     

      const response = await fetch(functionUrl, {
        method: 'GET', // The Cloud Function is triggered with a GET request
      });

      const result = await response.text();
      console.log(result); // Logs the result from Firebase Cloud Function
      Alert.alert('Success', 'Notification sent successfully!');
    } catch (error) {
      console.error('Error sending notification:', error);
      Alert.alert('Error', 'Failed to send notification');
    } finally {
      setLoading(false);
    }
  };

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>Send Push Notification</Text>
      <Button title={loading ? 'Sending...' : 'Send Notification'} onPress={sendNotification} disabled={loading} />
    </View>
  );
};

export default Notification;

// UserContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { database } from '../componenets/firebase'; // Importing database instance

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [globalUserId, setGlobalUserId] = useState(null);  // Renamed to globalUserId
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            const currentUser = auth.currentUser;
            if (currentUser) {
                try {
                    setGlobalUserId(currentUser.uid);  // Set the globalUserId
                    const usersRef = collection(database, 'users');
                    const q = query(usersRef, where('userId', '==', currentUser.uid));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        const userData = querySnapshot.docs[0].data();
                        setUser(userData);
                    } else {
                        console.log('No such user2!');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                } finally {
                    setLoading(false); // Set loading to false when done
                }
            } else {
                setLoading(false); // Set loading to false if no user is signed in
            }
        };

        fetchUserData();
    }, [auth]);

    return (
        <UserContext.Provider value={{ user, globalUserId, setUser }}>
            {!loading && children}  
        </UserContext.Provider>
    );
};

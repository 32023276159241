import React, { useState, useEffect, useMemo } from "react";
import { View, Text, Pressable, StyleSheet } from "react-native";
import { database } from "../componenets/firebase";
import { FontAwesome } from "@expo/vector-icons";
import {
  setDoc,
  doc,
  onSnapshot,
  collection,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import moment from "moment";  // Import moment

export default function RadioButton({
  data,
  onSelect,
  mainGame,
  TheGameId,
  playerId,
  name,
  email,
  GameDate,
  GameIndex,
}) {
  const [userOption, setUserOption] = useState(3);
  const [sides, setSides] = useState([]);
  






  const selectHandler = (value) => {
    onSelect(value);
    setUserOption(value);
  };

  const SaveRadio = async (item) => {
    const docRef = doc(
      database,
      "MainGame",
      mainGame,
      "TheGame",
      TheGameId,
      "Players",
      playerId
    );
    setDoc(
      docRef,
      {
        Name: name,
        value: item.value,
        ButtonId: item.id,
        id: playerId,
        userId: playerId,
        email: email,
        gameId: TheGameId,
        mainGameId: mainGame,
        LastChanged: getAuth().currentUser.email,
        lastChangedTimestamp: Timestamp.now(), // Add timestamp here
        gamedate: GameDate,
        date: GameDate,
        score: null,
        scoreAway: null,
        scoreHome: null,
        gameIndex: GameIndex,
       // test: "test",
      },
      { merge: true }
    );
  };

  useEffect(() => {
    const colRef = collection(
      database,
      "MainGame",
      mainGame,
      "TheGame",
      TheGameId,
      "Players"
    );
    const q = query(colRef, where("userId", "==", playerId));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        querySnapshot.docs.forEach((doc) => {
          setSides([{ ...doc.data(), id: doc.id }]);
        });
      } else {
        setSides([{ ButtonId: 3 }]); // Default to "Waiting"
      }
    });

    return () => {
      unsubscribe();
    };
  }, [mainGame, TheGameId, playerId]);

  const radioButtons = useMemo(() => {
    return data.map((item, index) => (
      <Pressable
        key={index}
        style={[
          styles.radioImage,
          sides[0]?.ButtonId === item.id && { backgroundColor: item.radiobg },
        ]}
        onPress={() => {
          selectHandler(item.id), SaveRadio(item);
        }}
      >
        <FontAwesome
          name={sides[0]?.ButtonId === item.PlayerId ? item.icon : "circle-o"}
          size={20}
          color={sides[0]?.ButtonId === item.id ? item.bg : "#000"}
        />
        <Text style={styles.radioText}> {item.value} </Text>
      </Pressable>
    ));
  }, [data, sides]);

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.radioButtonsContainer}>
          {radioButtons}
          <View style={styles.changedByContainer}>
            <Text style={styles.changedByEmail}>
              {sides[0]?.LastChanged ? `Changed by: ${sides[0].LastChanged}` : ""}
            </Text>
            {sides[0]?.lastChangedTimestamp && (
              <Text style={styles.timestampText}>
                {moment(sides[0].lastChangedTimestamp.toDate()).format('D MMM YYYY, h:mm A')}
              </Text>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  radioButtonsContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  radioImage: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 80, // changed from 100
    height: 30, // changed from 40
    borderRadius: 4, // changed from 5
    borderWidth: 1, // changed from 2
    borderColor: "#1E0FCA",
    marginHorizontal: 2,
    padding: 4, // changed from 5
  },
  radioText: {
    fontSize: 10, // changed from 10
    fontWeight: "500",
  },
  changedByContainer: {
    flexDirection: "column", // Stack Changed by and Timestamp vertically
    marginLeft: 10,
  },
  changedByEmail: {
    fontSize: 10, // changed from 12
    fontWeight: "500",
    color: "#1E0FCA",
  },
  timestampText: {
    fontSize: 10, // Adjust font size as needed
    fontWeight: "500",
    color: "#666", // Lighter color for the timestamp
    marginTop: 2, // Space between email and timestamp
  },
});

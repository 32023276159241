import React, { useState, useEffect } from "react";
import {
  Modal,
  View,
  Text,
  Pressable,
  StyleSheet
} from "react-native";
import { doc, getDoc } from "firebase/firestore";
import { database } from "../componenets/firebase"; // Adjust the path to your Firebase setup

const PointSystemModal = ({ gameSettings, modalVisible, setModalVisible, TheGameId, mainGameId }) => {
  const [savedByName, setSavedByName] = useState("Unknown");

  // Fetch the name of the user who saved the scores
  useEffect(() => {
    const fetchSavedByName = async () => {
      try {
        const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId); // Reference to the game document
        const gameSnapshot = await getDoc(gameRef);
        if (gameSnapshot.exists()) {
          const gameData = gameSnapshot.data();
          const savedBy = gameData?.savedBy;
          setSavedByName(savedBy?.name || "Unknown");
          console.log("Saved by:", savedBy?.name);
        }
      } catch (error) {
        console.error("Error fetching saved by name:", error);
      }
    };

    fetchSavedByName(); // Fetch the savedBy name when modal loads
  }, [TheGameId, mainGameId]); // Dependencies on TheGameId and mainGameId


  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}  // Close on back button or outside touch
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalText}>Points Configure</Text>
          <Text>
            <Text style={{ fontWeight: "bold" }}>Points enabled: {gameSettings?.pointsEnabled ? "Yes" : "No"}</Text>
          </Text>
          <Text>Win Points: {gameSettings?.winPoints || 0}</Text>
          <Text>Draw Points: {gameSettings?.drawPoints || 0}</Text>
          <Text>Lose Points: {gameSettings?.losePoints || 0}</Text>
          <Text>Attendance Points: {gameSettings?.attendancePoints || 0}</Text>
          <Text>Ten Goal Points: {gameSettings?.TeenGoalPoints || 0}</Text>
          <Text>Absent Points: {gameSettings?.absentPoints || 0}</Text>
          <Text>Saved by: {savedByName}</Text> 

          <Pressable
            style={[styles.button, styles.buttonClose]}
            onPress={() => setModalVisible(false)}  
          >
            <Text style={styles.textStyle}>Close</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  buttonClose: {
    backgroundColor: "#f44336",
    borderRadius: 10,
    padding: 10,
    marginTop: 20
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18
  },
});

export default PointSystemModal;

import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
 
  TouchableOpacity,
  SafeAreaView,
  TextInput,
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  ScrollView,

  TouchableWithoutFeedback,
  Keyboard, // Make sure to add this
  ImageBackground,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { Button } from "react-native-elements";


import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { auth } from "../../componenets/firebase"; // Ensure the correct path
import { database } from "../../componenets/firebase"; // Correct the path if necessary
import { doc, setDoc, serverTimestamp, updateDoc, increment  } from "firebase/firestore"; // Import Firestore functions


const SignInScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [inputFocus, setInputFocus] = useState({
    email: false,
    password: false,
  });

  const handleFocus = (field) => {
    setInputFocus((prev) => ({ ...prev, [field]: true }));
  };

  const handleBlur = (field) => {
    setInputFocus((prev) => ({ ...prev, [field]: false }));
  };

  // useEffect(() => {
  //   const checkLoginStatus = async () => {
  //     const isLoggedIn = await AsyncStorage.getItem('isLoggedIn');
  //     if (isLoggedIn) {
  //       navigation.navigate('Home'); // Adjust 'Home' to your app's home screen
  //     }
  //   };
  //   checkLoginStatus();
  // }, [navigation]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, navigate them to the home screen or wherever you'd like
        navigation.navigate("Home"); // Replace "Home" with your home screen's identifier
      }
      // If no user is signed in, stay on the sign-in screen
    });

    // Clean up subscription on unmount
    return unsubscribe;
  }, [navigation]);

  // Utility function to convert Firebase auth error codes to user-friendly messages
  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case "auth/user-not-found":
        return "No user found with this email. Please check your email address or sign up.";
      case "auth/wrong-password":
        return 'Incorrect password. Please try again or use the "Forgot password" option.';
      case "auth/too-many-requests":
        return "Too many attempts. Please try again later.";
      case "auth/email-already-in-use":
        return "Email is already in use by another account. Please use a different email.";
      // Add more cases for other Firebase Auth error codes as needed
      default:
        return "An unexpected error occurred. Please try again later.";
    }
  };

  
  const login = async () => {
    if (!email || !password) {
      setValidationMessage("Please fill in all required fields.");
      return;
    }
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      // Log specific user details
      const user = userCredential.user;
      console.log({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        lastLoginAt: user.metadata.lastLoginAt,
      });
  
      await AsyncStorage.setItem("isLoggedIn", "true");
  
      // You can remove the following Firestore update logic as requested:
      // const userDocRef = doc(database, "users", user.uid);     
      // await setDoc(userDocRef, { lastLogin: serverTimestamp()}, { merge: true });
      // await updateDoc(userDocRef, {
      //   loginCount: increment(1)
      // });
  
    } catch (error) {
      console.error("Login failed:", error);
      setValidationMessage(error.message);
    } finally {
      setLoading(false);
    }
  };
  

  // const login = async () => {
  //   if (!email || !password) {
  //     setValidationMessage("Please fill in all required fields.");
  //     return;
  //   }
  //   setLoading(true);
  //   try {
  //     await signInWithEmailAndPassword(auth, email, password);
  //     await AsyncStorage.setItem("isLoggedIn", "true"); // Consider using a more secure storage option for sensitive information
  //     setLoading(false);

  //     //navigation.navigate('Home'); // Adjust 'Home' to your app's home screen
  //   } catch (error) {
  //     const readableMessage = getErrorMessage(error.code);
  //     setValidationMessage(readableMessage);
  //     setLoading(false);
  //   }
  // };

  return (
    <ImageBackground
      source={require("../../assets/background.jpg")}
      style={styles.backgroundImage}
    >
      <SafeAreaView style={styles.container}>
        <KeyboardAvoidingView
        behavior={Platform.select({
            ios: "padding",
            android: "height",
            web: "height",
          })}
          style={styles.keyboardAvoidingView}
        >
        <ScrollView contentContainerStyle={styles.innerContainer}>
            <Text style={styles.title}>Login to TeamPlay</Text>        
            

            <View style={styles.inputContainer}>

              <MaterialIcons name="email" size={24} color="white" />
              
              <TextInput
  style={[
    styles.inputStyle,
    inputFocus.email && styles.inputFocused, // Correctly reference `inputFocus.email` here
  ]}
  placeholder="Email"
  placeholderTextColor="white"
  value={email}
  onChangeText={setEmail}
  keyboardType="email-address"
  autoCapitalize="none"
  onFocus={() => handleFocus("email")}
  onBlur={() => handleBlur("email")}
/>

            </View>

            <View style={styles.inputContainer}>
              <MaterialIcons name="lock" size={24} color="white" />
              <TextInput
  style={[
    styles.inputStyle,
    inputFocus.password && styles.inputFocused, // Correctly reference `inputFocus.password` here
  ]}
  placeholder="Password"
  placeholderTextColor="white"
  secureTextEntry={true}
  value={password}
  onChangeText={setPassword}
  onFocus={() => handleFocus("password")}
  onBlur={() => handleBlur("password")}
/>
            </View>

            {validationMessage !== "" && (
              <Text style={styles.error}>{validationMessage}</Text>
            )}

            {loading ? (
              <ActivityIndicator size="large" color="#0000ff" />
            ) : (
              <Button
                title="Sign in"
                buttonStyle={styles.button}
                onPress={login}
              />
            )}

            <TouchableOpacity onPress={() => navigation.navigate("Sign Up")}>
              <Text style={styles.signUpText}>
                Don't have an account yet? Sign up here
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => navigation.navigate("ForgotPassword")}
            >
              <Text style={styles.forgotPasswordText}>Forgot Password?</Text>
              </TouchableOpacity>
          </ScrollView>
        </KeyboardAvoidingView>
      </SafeAreaView>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  container: {
    flex: 1,
    backgroundColor: "transparent",
  },
  keyboardAvoidingView: {
    flex: 1,
    width: "100%",
  },
  innerContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 20,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    color: "white",
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: Platform.OS === "ios" ? "80%" : "30%",
    marginVertical: 12,
    borderBottomWidth: 1,
    borderColor: "gray", // Consider changing this if you want the border to be more visible on the background
    paddingVertical: 10,
  },


  button: {
    marginTop: 20,
    paddingHorizontal: 20,
  },
  inputStyle: {
    marginLeft: Platform.OS === "ios" ? 0 : 10, // Conditional marginLeft based on the platform
    flex: 1,
    fontSize: 16,
    color: "white",
  },
  error: {
    color: "red",
    marginTop: 10,
  },
  signUpText: {
    marginTop: 20,
    color: "#000000",
    fontSize: 16,

    backgroundColor: "#D3D3D3",
  },
  inputFocused: {
    borderColor: "#E6E6FA",
    borderWidth: 2,
  },
  forgotPasswordText: {
    color: "#000000",
    marginTop: 15,
    backgroundColor: "#D3D3D3",
    fontSize: 16,

  },


  

   // Existing styles remain unchanged
   inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "90%", // Keep the responsive width
    maxWidth: 400, // Add a maxWidth to prevent the fields from becoming too wide on larger screens
    marginVertical: 12,
    borderBottomWidth: 1,
    borderColor: "gray",
    paddingVertical: 10,
    alignSelf: 'center', // Ensure the container is centered within its parent
  },
 
inputStyle: {
  marginLeft: Platform.select({ ios: 0, android: 10, web: 10 }), // Adjust marginLeft based on platform, including a specific style for web
  flex: 1,
  fontSize: 16,
  color: "white",
},
  inputFocused: {
    borderColor: "#E6E6FA",
    borderWidth: 2,
    padding: 8, // Consider if additional padding is needed for focused state
    backgroundColor: '#9D9B9A', // A light grey color

  },



  
});

export default SignInScreen;

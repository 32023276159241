// sort name and sort rank works

import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  SafeAreaView,
  FlatList,
  StyleSheet,
  Pressable,
  ScrollView,
  ActivityIndicator,
  TextInput,
} from "react-native";
import { database, auth  } from "../componenets/firebase";
import {
  collectionGroup,
  query,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  where,
  writeBatch,
  collection,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import PointSystemModal from './ModalPointSystem'; // Adjust the path if needed
import { MaterialIcons } from "@expo/vector-icons"; // Import MaterialIcons from Expo



//import { LinearGradient } from 'expo-linear-gradient'; // For background gradient (Expo's library)

const Results = ({ navigation, route }) => {
  const { mainGame, mainGame : mainGameId, TheGameId, gameDate } = route.params;
  const [playerData, setPlayerData] = useState([]);
  const [gameSettings, setGameSettings] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [sortBy, setSortBy] = useState("Rank"); // State to track sorting criteria (default is Name)
  const originalData = useRef([]); // Store original data for sorting, using useRef to preserve data between renders
  const [scoreHome, setScoreHome] = useState(""); // For Home team score
  const [scoreAway, setScoreAway] = useState(""); // For Away team score
  const [teamSelections, setTeamSelections] = useState({}); // Store team selection per userId
  const [modalVisible, setModalVisible] = useState(false); // Modal state

  const homeCount = playerData.filter(player => teamSelections[player.userId] === "Home").length;
  const awayCount = playerData.filter(player => teamSelections[player.userId] === "Away").length;
  const absentCount = playerData.filter(player => teamSelections[player.userId] === "Absent").length;
  const [isAdmin, setIsAdmin] = useState(false); // Add isAdmin state

  

  //const [pointsEnabled, setPointsEnabled] = useState(false);


  // UseEffects

  useEffect(() => {
    const fetchIsAdminStatus = async () => {
      try {
        const mainGameDoc = doc(database, "MainGame", mainGameId);
        const mainGameSnapshot = await getDoc(mainGameDoc);
        const { memberOfGame } = mainGameSnapshot.data();

        const currentUser = auth.currentUser;

        if (currentUser) {
          const currentUserId = currentUser.uid;
          const currentUserData = memberOfGame.find(
            (member) => member.uid === currentUserId
          );

          if (currentUserData) {
            const adminStatus = currentUserData.isAdmin;
            setIsAdmin(adminStatus === true);
          }
        }
      } catch (error) {
        console.error("Error in fetchIsAdminStatus:", error);
      }
    };

    fetchIsAdminStatus();
  }, [mainGameId]);

   // Fetch game Name
   useEffect(() => {
    const fetchGameSettings = async () => {
      const gameSettingsRef = doc(database, "MainGame", mainGameId);
      const gameSettingsSnap = await getDoc(gameSettingsRef);
      if (gameSettingsSnap.exists()) {
        setGameSettings(gameSettingsSnap.data());
      }
    };
    fetchGameSettings();
  }, [mainGameId]);

  // update score in leaderboard
  useEffect(() => {
    const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
  
    const unsubscribe = onSnapshot(gameRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const gameData = docSnapshot.data();
        setScoreHome(gameData.scoreHome || 0); // Update home score
        setScoreAway(gameData.scoreAway || 0); // Update away score
      }
    });
  
    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [mainGameId, TheGameId]);
  

  // Fetch game points settings
  // Fetch game settings / points and log them
useEffect(() => {
  const fetchGameSettings = async () => {
    try {
      const gameSettingsRef = doc(database, "MainGame", mainGameId);
      const gameSettingsSnap = await getDoc(gameSettingsRef);

      if (gameSettingsSnap.exists()) {
        const settingsData = gameSettingsSnap.data();
        setGameSettings(settingsData);
        
      
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching game settings:", error);
    }
  };

  fetchGameSettings();
}, [mainGameId, TheGameId]); // Dependencies for useEffect




  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        // Fetch game settings and score
        const gameSettingsRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
        const gameSettingsSnap = await getDoc(gameSettingsRef);
        if (gameSettingsSnap.exists()) {
          const gameData = gameSettingsSnap.data();
          setScoreHome(gameData.scoreHome || 0);  // Fetch and set home score
          setScoreAway(gameData.scoreAway || 0);  // Fetch and set away score
          console.log(`Fetched scores: Home - ${gameData.scoreHome}, Away - ${gameData.scoreAway}`);
        }
  
        // Fetch team selections
        const playersRef = collection(database, "MainGame", mainGameId, "TheGame", TheGameId, "Players");
        const currentGameSnapshot = await getDocs(playersRef);
  
        let selections = {};
        currentGameSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          selections[doc.id] = data.team || null; // Set team from Firestore or null if not set
        });
  
        // Log fetched team selections
        console.log("Fetched team selections:", selections);
  
        // Update local state with team selections
        setTeamSelections(selections);
      } catch (error) {
        console.error("Error fetching game details:", error);
      }
    };
  
    fetchGameDetails();
  }, [mainGameId, TheGameId]);  // Trigger fetch on screen load
  


  //Trigger listener on screen load
  useEffect(() => {
    const subscribeToTeamSelections = () => {
      const playersRef = collection(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId,
        "Players"
      );

      // Set up a real-time listener with onSnapshot
      const unsubscribe = onSnapshot(playersRef, (snapshot) => {
        let selections = {};
        snapshot.docs.forEach((doc) => {
          const data = doc.data();
          selections[doc.id] = data.team || null; // Update team from Firestore
        });

        // Update local state with real-time team selections
        setTeamSelections(selections);
      });

      // Clean up listener on unmount
      return () => unsubscribe();
    };

    subscribeToTeamSelections();
  }, [mainGameId, TheGameId]); // Trigger listener on screen load

  useEffect(() => {
    const fetchTeamSelections = async () => {
      try {
        const playersRef = collection(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players"
        );
        const currentGameSnapshot = await getDocs(playersRef);

        let selections = {};
        currentGameSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          selections[doc.id] = data.team || null; // Set team from Firestore or null if not set
        });

        // Update local state with fetched team selections
        setTeamSelections(selections);
      } catch (error) {
        console.error("Error fetching team selections:", error);
      }
    };

    fetchTeamSelections();
  }, [mainGameId, TheGameId]); // Trigger fetch on screen load




  // Fetch game settings
  useEffect(() => {
    const fetchAndRankAllPlayers = async () => {
      try {
        setLoading(true);
  
        // Step 1: Fetch all players across all games for the main game
        const allPlayersQuery = query(
          collectionGroup(database, "Players"),
          where("mainGameId", "==", mainGame) // Fetch players for the main game
        );
  
        const allPlayersSnapshot = await getDocs(allPlayersQuery); // Fetch data for all players
        console.log("Fetched all players:", allPlayersSnapshot.docs.length);
        let allPlayers = {};
  
        // Step 2: Process player data and filter those who are 'Going'
        allPlayersSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          const playerKey = data.userId;
  
          // Only process players who are marked as "Going"
          if (data.value === "Going") {
            if (!allPlayers[playerKey]) {
              allPlayers[playerKey] = {
                Name: data.Name,
                email: data.email || "",
                userId: playerKey,
                totalPoints: 0,
                totalGF: 0,
                totalGA: 0,
                totalGD: 0,
                team: data.team || null,
                rank: 0,  // Placeholder for rank
              };
            }
  
            // Update player's cumulative stats
            if (data.totalPoints != null) {
              allPlayers[playerKey].totalPoints += data.totalPoints;
  
              if (data.team === "Home") {
                allPlayers[playerKey].totalGF += data.scoreHome || 0;
                allPlayers[playerKey].totalGA += data.scoreAway || 0;
              } else {
                allPlayers[playerKey].totalGF += data.scoreAway || 0;
                allPlayers[playerKey].totalGA += data.scoreHome || 0;
              }
  
              allPlayers[playerKey].totalGD =
                allPlayers[playerKey].totalGF - allPlayers[playerKey].totalGA;
            }
          }
        });
  
        // Step 3: Sort players by cumulative stats and assign ranks
        let allPlayersArray = Object.values(allPlayers);
        allPlayersArray = allPlayersArray.sort((a, b) => {
          if (b.totalPoints !== a.totalPoints) return b.totalPoints - a.totalPoints;
          if (b.totalGD !== a.totalGD) return b.totalGD - a.totalGD;
          if (b.totalGF !== a.totalGF) return b.totalGF - a.totalGF;
          return a.Name.localeCompare(b.Name); // Tie-breaker by name
        });
  
        // Assign ranks based on sorted order
        let rank = 1;
        for (let i = 0; i < allPlayersArray.length; i++) {
          if (
            i > 0 &&
            allPlayersArray[i].totalPoints === allPlayersArray[i - 1].totalPoints &&
            allPlayersArray[i].totalGD === allPlayersArray[i - 1].totalGD &&
            allPlayersArray[i].totalGF === allPlayersArray[i - 1].totalGF
          ) {
            allPlayersArray[i].rank = allPlayersArray[i - 1].rank; // Tie rank
          } else {
            allPlayersArray[i].rank = rank;
          }
          rank++;
        }
  
        // Step 4: Store ranked players
        originalData.current = allPlayersArray;
  
      // Step 5: Fetch players for the specific TheGameId and filter them by rank
const playersRef = collection(
  database,
  "MainGame",
  mainGame,
  "TheGame",
  TheGameId,
  "Players"
);
const currentGameSnapshot = await getDocs(playersRef);

let currentGamePlayers = [];

currentGameSnapshot.docs.forEach((doc) => {
  const playerData = doc.data();
  const playerId = doc.id;

  // Only include players who are marked as "Going"
  if (playerData.value === "Going") {
    const playerInAllPlayers = allPlayersArray.find((p) => p.userId === playerId); // Find player in global rank list

    if (playerInAllPlayers) {
      currentGamePlayers.push(playerInAllPlayers); // Add player with global rank and cumulative stats
    }
  }
});

  
// Sort based on the selected criterion (by name or rank)
if (sortBy === "Name") {
  currentGamePlayers = currentGamePlayers.sort((a, b) =>
    a.Name.localeCompare(b.Name)
  );
} else if (sortBy === "Rank") {
  // Sort by Rank first, then by Name as a secondary criterion
  currentGamePlayers = currentGamePlayers.sort((a, b) => {
    if (a.rank === b.rank) {
      return a.Name.localeCompare(b.Name); // If ranks are the same, sort by Name
    }
    return a.rank - b.rank; // Sort by Rank
  });
}

  
        setPlayerData(currentGamePlayers); // Update state with current game players and global ranks
      } catch (error) {
        console.error("Error fetching player data:", error);
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };
  
    fetchAndRankAllPlayers();
  }, [mainGame, TheGameId, sortBy]);
  
  
  
  
  

  // Clear all "Home" and "Away" team selections
  const clearAllTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Reset team selections in local state
      setTeamSelections({});

      // Create a Firestore batch to clear team selections in Firestore
      const batch = writeBatch(database);

      playerData.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(playerRef, { team: null }, { merge: true });
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("All teams cleared successfully in batch");
    } catch (error) {
      console.error("Error clearing teams in Firestore:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Randomize and save teams
  const randomizeAndSaveTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Randomly assign teams
      const shuffledPlayers = [...playerData].sort(() => 0.5 - Math.random()); // Shuffle the players
      const updatedTeamSelections = {};
      const halfTeamSize = Math.ceil(shuffledPlayers.length / 2);

      shuffledPlayers.forEach((player, index) => {
        updatedTeamSelections[player.userId] =
          index < halfTeamSize ? "Home" : "Away";
      });

      // Update local state with random teams
      setTeamSelections(updatedTeamSelections);

      // Create a Firestore batch
      const batch = writeBatch(database);

      // Update Firestore with new team selections
      shuffledPlayers.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(
          playerRef,
          { team: updatedTeamSelections[player.userId] },
          { merge: true }
        );
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("Teams randomized and saved successfully.");
    } catch (error) {
      console.error("Error randomizing and saving teams:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Toggle (alternating) teams Home/Away
  const assignAlternatingTeams = async () => {
    try {
      setLoading(true); // Show loading spinner

      // Alternate teams between Home and Away
      const updatedTeamSelections = {};
      playerData.forEach((player, index) => {
        updatedTeamSelections[player.userId] =
          index % 2 === 0 ? "Home" : "Away";
      });

      // Update local state with alternating teams
      setTeamSelections(updatedTeamSelections);

      // Create a Firestore batch
      const batch = writeBatch(database);

      // Update Firestore with new alternating team selections
      playerData.forEach((player) => {
        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId
        );
        batch.set(
          playerRef,
          { team: updatedTeamSelections[player.userId] },
          { merge: true }
        );
      });

      // Commit the batch to Firestore
      await batch.commit();

      console.log("Teams assigned in alternating Home/Away pattern.");
    } catch (error) {
      console.error("Error assigning teams in Firestore:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Function to update player's team in Firestore and state WITHOUT re-sorting, using background syncing
  const updatePlayerTeam = (userId, team) => {
    if (!userId || !mainGameId || !TheGameId) {
      console.error("Missing parameters for Firestore doc path");
      return;
    }

    // Update the playerData state for the specific player WITHOUT waiting for Firestore response
    const updatedData = playerData.map((player) =>
      player.userId === userId ? { ...player, team: team } : player
    );

    setPlayerData(updatedData); // Update only the relevant player's team in the list

    // Perform Firestore update in the background, without blocking the UI
    const playerRef = doc(
      database,
      "MainGame",
      mainGameId,
      "TheGame",
      TheGameId,
      "Players",
      userId
    );

    setDoc(playerRef, { team: team }, { merge: true })
      .then(() => {
        console.log(
          `Player team selection updated successfully for userId: ${userId}`
        );
      })
      .catch((error) => {
        console.error("Error updating player team in Firestore:", error);
      });
  };

  // save score logic:
  // Define the calculatePoints function
  const calculatePoints = (
    scoreHome, 
    scoreAway, 
    playerTeam, 
    gameSettings, 
    fouls = 0, 
    isAbsent = false
  ) => {
    // Default points configuration
    let drawPointsConfig = 0;
    let winPointsConfig = 1;
    let losePointsConfig = 0;
    let attendancePointsConfig = 0;
    let teamGoalPointsConfig = 0;
    let absentPointsConfig = 0;
  
    // Override default points if gameSettings is available and pointsEnabled is true
    if (gameSettings?.pointsEnabled) {
      drawPointsConfig = gameSettings.drawPoints ?? 0;
      winPointsConfig = gameSettings.winPoints ?? 1;
      losePointsConfig = gameSettings.losePoints ?? 0;
      attendancePointsConfig = gameSettings.attendancePoints ?? 0;
      teamGoalPointsConfig = gameSettings.TeenGoalPoints ?? 0;
      absentPointsConfig = gameSettings.absentPoints ?? 0;
    }
  
    // Log the playerTeam for debugging
    console.log(`Calculating points for ${playerTeam || 'Unknown Team'} | Home: ${scoreHome}, Away: ${scoreAway}`);
  
    // If playerTeam is undefined or not properly passed
    if (!playerTeam) {
      console.error('Error: playerTeam is not defined for this player.');
      return;
    }
  
    let drawPoints = 0,
        winPoints = 0,
        losePoints = 0,
        attendancePoints = 0,
        teenGoalPoints = 0,
        absentPoints = 0,
        result = "";
  
    // Handle absent points directly if the player is absent
    if (isAbsent) {
      absentPoints = absentPointsConfig;
      console.log(`${playerTeam} is absent. Assigning absent points: ${absentPoints}`);
    } else {
      // Attendance points if either team has scored
      attendancePoints = (scoreHome > 0 || scoreAway > 0) ? attendancePointsConfig : 0;
  
      // Determine the game result for this player
      if (scoreHome === scoreAway) {
        drawPoints = drawPointsConfig;
        result = "draw"; // Game ended in a draw
        console.log(`Game ended in a draw.`);
      } else if (playerTeam === "Home" && scoreHome > scoreAway) {
        winPoints = winPointsConfig;
        result = "win"; // Home team wins
        console.log(`Home team won.`);
      } else if (playerTeam === "Away" && scoreAway > scoreHome) {
        winPoints = winPointsConfig;
        result = "win"; // Away team wins
        console.log(`Away team won.`);
      } else {
        losePoints = losePointsConfig;
        result = "lost"; // Player's team lost
        console.log(`${playerTeam} team lost.`);
      }
  
      // Award Teen Goal Points if the player's team scored 10 or more goals
      if ((playerTeam === "Home" && scoreHome >= 10) || (playerTeam === "Away" && scoreAway >= 10)) {
        teenGoalPoints = teamGoalPointsConfig;
        console.log(`Awarding Teen Goal Points: ${teenGoalPoints}`);
      }
    }
  
    // Total points calculation (attendance + win/draw/lose - fouls + absent points)
    const totalPoints = winPoints + drawPoints + attendancePoints + teenGoalPoints - fouls + absentPoints;
  
    // Log the result for each player
    console.log(`Player on team ${playerTeam} has result: ${result}`);
    console.log("Points Breakdown:");
    console.log(`WinPoints: ${winPoints}, DrawPoints: ${drawPoints}, LosePoints: ${losePoints}`);
    console.log(`AttendancePoints: ${attendancePoints}, TeenGoalPoints: ${teenGoalPoints}, Fouls: ${fouls}`);
    console.log(`TotalPoints: ${totalPoints}, Result: ${result}`);
    console.log("--------------------");  // Separator for better readability
  
    // Return the calculated points
    return {
      teenGoalPoints,
      drawPoints,
      winPoints,
      losePoints,
      attendancePoints,
      totalPoints,
      result,
      fouls,
      absentPoints
    };
  };
  
  

  

  // Update team selection when user selects Home or Away
  const updateTeamSelection = (userId, team) => {
    // Update the local state
    setTeamSelections((prev) => ({
      ...prev,
      [userId]: team,
    }));

    // Update the Firestore document for the specific player
    const playerRef = doc(
      database,
      "MainGame",
      mainGameId,
      "TheGame",
      TheGameId,
      "Players",
      userId
    );

    setDoc(playerRef, { team: team }, { merge: true }) // Update Firestore with the selected team
      .then(() => {
        console.log(
          `Player team selection updated successfully for userId: ${userId}`
        );
      })
      .catch((error) => {
        console.error("Error updating player team in Firestore:", error);
      });
  };

  // save score to firebase
  const savePlayerDataToFirestore = async (
    mainGameId,
    TheGameId,
    playerData
  ) => {
    const batch = writeBatch(database);

    playerData.forEach((player) => {
      if (player && player.userId) {
        player.fouls = player.fouls || 0; // Default to 0 if fouls is not set.

        // Ensure the team field is not undefined, default to an empty string
        const team = player.team || "";

        // Calculate points and other relevant data
        const {
          teenGoalPoints,
          drawPoints,
          winPoints,
          losePoints,
          attendancePoints,
          totalPoints,
          result,
          fouls,
        } = calculatePoints(scoreHome, scoreAway, team, player.fouls);

        // Log the data being saved
        console.log(`Saving data for userId: ${player.userId}`);
        console.log({
          scoreHome,
          scoreAway,
          team,
          teenGoalPoints,
          drawPoints,
          winPoints,
          losePoints,
          attendancePoints,
          totalPoints,
          result,
          fouls,
        });

        const playerRef = doc(
          database,
          "MainGame",
          mainGameId,
          "TheGame",
          TheGameId,
          "Players",
          player.userId // Use player.userId from your existing data
        );

        batch.set(
          playerRef,
          {
            scoreHome,
            scoreAway,
           // team: team, // Use the team variable that ensures it's not undefined
            teenGoalPoints,
            drawPoints,
            winPoints,
            losePoints,
            attendancePoints,
            totalPoints,
            result,
            fouls: player.fouls,
          },
          { merge: true }
        );
      }
    });


  // Save scores to "TheGame" document
  const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
  batch.set(
    gameRef,
    {
      scoreHome: scoreHome,  // Save Home team score
      scoreAway: scoreAway,  // Save Away team score
    },
    { merge: true }  // Merge with existing data
  );

    try {
      await batch.commit();
      console.log("All player data saved successfully.");
    } catch (error) {
      console.error("Error saving team data: ", error);
    }
  };


// find name from uid. 
const fetchUserName = async (userId) => {
  try {
    const userRef = doc(database, "users", userId); // Reference to the user's document in Firestore
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const { name } = userSnapshot.data(); // Extract the 'name' field from the user document
      return name;
    } else {
      console.error("No such user document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user name:", error);
    return null;
  }
};

  // Function to handle saving scores
  const handleSaveScores = async () => {
    const userId = auth.currentUser.uid;
    const userName = await fetchUserName(userId);


    const batch = writeBatch(database);
  
    const updatedPlayerData = playerData.map((player) => {
      const updatedPlayer = { ...player };
      updatedPlayer.scoreHome = scoreHome; // Set scoreHome from state or input
      updatedPlayer.scoreAway = scoreAway; // Set scoreAway from state or input
  
      // Get the player's team from teamSelections
      const playerTeam = teamSelections[updatedPlayer.userId];
  
      // Log the player's name and team to verify
      console.log(`Player: ${updatedPlayer.Name}, Team: ${playerTeam}`);
  
      // Check if playerTeam is missing, and log an error if it is
      if (!playerTeam) {
        console.error(`Error: Player ${updatedPlayer.Name} does not have a team assigned.`);
        return updatedPlayer; // Skip this player if no team is assigned
      }
  
      // Calculate points for each player, including absent points
      const { totalPoints, winPoints, drawPoints, losePoints, attendancePoints, teenGoalPoints, absentPoints, result } =
        calculatePoints(scoreHome, scoreAway, playerTeam, gameSettings, player.fouls || 0, playerTeam === "Absent");
  
      // Log the calculated points and result for debugging
      console.log(`Points and result for ${updatedPlayer.Name}:`, {
        totalPoints,
        winPoints,
        drawPoints,
        losePoints,
        attendancePoints,
        teenGoalPoints,
        absentPoints,
        result,
      });
  
      // Save points and result to Firestore for each player
      const playerRef = doc(
        database,
        "MainGame",
        mainGameId,
        "TheGame",
        TheGameId,
        "Players",
        player.userId
      );
  
      batch.set(
        playerRef,
        {
          scoreHome,
          scoreAway,
          winPoints,
          drawPoints,
          losePoints,
          attendancePoints,
          teenGoalPoints,
          totalPoints,
          absentPoints, // Save absent points
          result,       // Save the result (win/loss/draw)
          fouls: player.fouls || 0, // Save fouls if applicable
        },
        { merge: true }
      );
  
      return updatedPlayer;
    });
  
// Save scores and user log to "TheGame" document - Save scores to "TheGame" document
  const gameRef = doc(database, "MainGame", mainGameId, "TheGame", TheGameId);
  batch.set(
    gameRef,
    {
      scoreHome: scoreHome,  // Save Home team score
      scoreAway: scoreAway,  // Save Away team score
      savedBy: {
        userId: userId,     // Save userId
        name: userName,     // Save user’s name
      },
    },
    { merge: true }  // Merge with existing data
  );


  
    try {
      await batch.commit();
      console.log("All player data and game scores saved successfully.");
    } catch (error) {
      console.error("Error saving team data: ", error);
    }
  };
  
  
  
  
  

  










  // Render each player row
  function RenderItem({ item, index }) {
    // Use team selection from the parent state
    const teamSelection = teamSelections[item.userId] || null; // Default to null if not selected
  
    // Function to handle team selection
    const handleTeamSelection = (team) => {
      const newTeamSelection = teamSelection === team ? null : team; // Toggle between Home, Away, or None
      updateTeamSelection(item.userId, newTeamSelection); // Update parent state
    };
  

    return (

      
      <View style={styles.playerRow}>

        
      {/* Display the counter (index + 1) */}
  

      <View>
        <Text style={styles.rank}>{item.rank}</Text>
      </View>

        <View style={{ flexDirection: "column", flexGrow: 1, marginLeft: 10 }}>
          <Text style={styles.playerName}>
            {item.Name.length > 20
              ? `${item.Name.substring(0, 20)}...`
              : item.Name}
          </Text>
          <Text style={styles.playerEmail}>{item.email}</Text>
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Pressable
            onPress={() => handleTeamSelection("Home")}
            style={[
              styles.teamButton,
              teamSelection === "Home" && styles.teamSelected,
            ]}
          >
            <Text>Home</Text>
          </Pressable>

          <Pressable
            onPress={() => handleTeamSelection("Away")}
            style={[
              styles.teamButton,
              teamSelection === "Away" && styles.teamSelected,
            ]}
          >
            <Text>Away</Text>
          </Pressable>

          <Pressable
            onPress={() => handleTeamSelection("Absent")}
            style={[
              styles.teamButton,
              teamSelection === "Absent" && styles.teamSelected,
            ]}
          >
            <Text>Absent</Text>
          </Pressable>

        </View>
      </View>
    );
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* Show spinner if loading */}
      {loading && (
        <View style={styles.loadingOverlay}>
          <ActivityIndicator size="large" color="#0000ff" />
        </View>
      )}

      {/* Top buttons */}
      <View style={styles.topButtonsContainer}>
        <Pressable
          style={styles.topButton}
          onPress={clearAllTeams}
          disabled={loading}
        >
          <Text>Clear Teams</Text>
        </Pressable>

        <Pressable
          style={styles.topButton}
          onPress={assignAlternatingTeams}
          disabled={loading}
        >
          <Text>Toggle Teams</Text>
        </Pressable>

        <Pressable
          style={styles.topButton}
          onPress={randomizeAndSaveTeams}
          disabled={loading}
        >
          <Text>Random Teams</Text>               
        </Pressable>   
        <View>

        <View>
  {/* Pressable to open modal */}
  <Pressable
    style={styles.iconButton}
    onPress={() => setModalVisible(true)}
  >
    <MaterialIcons name="info" size={30} color="#2196F3" />
  </Pressable>

  {/* PointSystemModal component */}
  <PointSystemModal
  gameSettings={gameSettings}
  modalVisible={modalVisible}
  setModalVisible={setModalVisible}
  mainGameId={mainGameId}   // Pass mainGameId to the modal
  TheGameId={TheGameId}     // Pass TheGameId to the modal
/>

</View>





  {/* PointSystemModal component */}
  {/* <PointSystemModal gameSettings={gameSettings} /> */}
</View>
     
      </View>

      <View>          
  {/* <Text>
    {gameSettings && gameSettings.pointsEnabled !== undefined 
      ? `Points Enabled: ${gameSettings.pointsEnabled}` 
      : "Loading points settings..."}
  </Text> */}
</View>

      <View style={styles.header}>
        <Text>
          Name: {gameSettings?.name ?? "Loading..."} | Date:{" "}
          {moment(gameDate).format("DD MMMM YYYY")}
        </Text>
      </View>


  


 
{/* Save Scores Button Inside the Score Table */}
<View style={styles.scoreTable}>

<Pressable
  style={({ pressed }) => [
    styles.saveButton,
    pressed && styles.saveButtonPressed, // Apply the pressed style when button is pressed
  ]}
  onPress={() => {
    if (isAdmin) {
      // If admin, save the scores
      handleSaveScores();
    }
    // Navigate to the GameDashboard for both admin and non-admin users
    navigation.navigate("GameDashboard", {
      gameDate,
      mainGameId,
      TheGameId,
    });
  }}
  android_ripple={{ color: "#fff", radius: 200 }} // Ripple effect on Android
>
  <Text style={styles.saveButtonText}>
    {isAdmin ? "Save Live Scores" : "Show Live Scores"}
  </Text>
</Pressable>




{/* 
<Pressable
  style={({ pressed }) => [
    styles.saveButton,
    pressed && styles.saveButtonPressed, // Apply the pressed style when button is pressed
  ]}
  onPress={handleSaveScores}
  android_ripple={{ color: "#fff", radius: 200 }} // Ripple effect on Android
>
  <Text style={styles.saveButtonText}>Save Live Scores!</Text>
</Pressable> */}


  {/* Colorful Leaderboard Table */}
  <View style={styles.scoreTableRow}>
  <View style={styles.teamSection}>
    <Text style={styles.homeLabel}>Home Team</Text>
    <TextInput
      style={[styles.scoreInput]}
      keyboardType="numeric"
      placeholder="0"
      returnKeyType="done"
      placeholderTextColor="#000"
      value={scoreHome.toString()} // Bind the state and ensure it displays as a string
      onChangeText={(value) => isAdmin && setScoreHome(parseInt(value) || 0)} // Only allow changes if user is admin
      editable={isAdmin} // Make it non-editable for non-admins
    />
  </View>

  <Text style={styles.vsLabel}>vs</Text>

  <View style={styles.teamSection}>
    <Text style={styles.awayLabel}>Away Team</Text>
    <TextInput
      style={[styles.scoreInput]}
      keyboardType="numeric"
      placeholder="0"
      returnKeyType="done"
      placeholderTextColor="#000"
      value={scoreAway.toString()} // Bind the state and ensure it displays as a string
      onChangeText={(value) => isAdmin && setScoreAway(parseInt(value) || 0)} // Only allow changes if user is admin
      editable={isAdmin} // Make it non-editable for non-admins
    />
  </View>
</View>


</View>


      {/* Sorting buttons */}
      <View style={styles.sortButtonsContainer}>
        <Pressable
          style={[
            styles.sortButton,
            sortBy === "Rank"
              ? styles.sortButtonActive
              : styles.sortButtonInactive,
          ]}
          onPress={() => setSortBy("Rank")}
        >
          <Text style={styles.sortButtonText}>Sort by Rank</Text>
        </Pressable>

        <Pressable
          style={[
            styles.sortButton,
            sortBy === "Name"
              ? styles.sortButtonActive
              : styles.sortButtonInactive,
          ]}
          onPress={() => setSortBy("Name")}
        >
          <Text style={styles.sortButtonText}>Sort by Name</Text>
        </Pressable>
      </View>

{/* Display the total counter for all players and teams */}
{/* Updated counterContainer with flex properties for alignment */}
<View style={styles.counterContainer}>
  <Text 
    style={[
      styles.counterText, 
      { 
        width: 80, 
        color: (homeCount + awayCount + absentCount === playerData.length && playerData.length > 0) ? 'green' : 'red' 
      }
    ]}
  >
    Total:  {homeCount + awayCount + absentCount} / {playerData.length}
  </Text>  
  {/* <Text style={styles.counterText}>Home: {homeCount}</Text>
  <Text style={styles.counterText}>Away: {awayCount}</Text> */}

  <Text 
  style={[
    styles.counterText, 
    { 
      color: (homeCount === awayCount && playerData.length > 0) ? 'green' : 'orange' 
    }
  ]}
>
  Home:  {homeCount} 
</Text>

{/* <Text style={styles.counterText}>Total Selected: {homeCount + awayCount + absentCount}</Text> */}

<Text 
  style={[
    styles.counterText, 
    { 
      color: (homeCount === awayCount && playerData.length > 0) ? 'green' : 'orange' 
    }
  ]}
>
  Away: {awayCount}
</Text>
<Text style={styles.counterText}>Absent: {absentCount}</Text>





</View>


      

      

      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>




        <FlatList
          data={playerData}
          ListHeaderComponent={() => (
            <View style={styles.listHeader}>
              <Text style={styles.headerText}>Rank</Text>
              <Text style={[styles.headerText, { flex: 1 }]}>Name</Text>
              <Text style={styles.headerText}>Team</Text>
            </View>
          )}
          renderItem={({ item }) => (
            <RenderItem item={item} updatePlayerTeam={updatePlayerTeam} />
          )}
          keyExtractor={(item) => item.userId}
          contentContainerStyle={styles.flatListContent}
        />
      </ScrollView>
    </SafeAreaView>
  );
};

export default Results;

const styles = StyleSheet.create({
  playerRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  rank: {
    fontSize: 16,
    fontWeight: "bold",
    marginRight: 10,
  },
  playerName: {
    fontSize: 14,
  },
  playerEmail: {
    fontSize: 12,
    color: "#666",
  },
  teamButton: {
    padding: 10,
    borderRadius: 8,
    backgroundColor: "#ddd",
    marginHorizontal: 5,
  },
  teamSelected: {
    backgroundColor: "#4C7BEE",
  },
  flatListContent: {
    paddingVertical: 10,
  },
  header: {
    padding: 10,
    backgroundColor: "#eee",
    alignItems: "center",
  },
  assignTeamsButton: {
    padding: 10,
    backgroundColor: "#4CAF50", // Green color for the button
    borderRadius: 8,
    margin: 10,
  },
  assignTeamsButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  clearTeamsButton: {
    padding: 10,
    backgroundColor: "#f44336", // Red color for the clear button
    borderRadius: 8,
    margin: 10,
  },
  clearTeamsButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  listHeader: {
    flexDirection: "row",
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: "#f0f0f0",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 16,
    width: 80, // Adjust width for Rank and Points
    textAlign: "left",
  },
  randomizeButton: {
    padding: 10,
    backgroundColor: "#FFA500", // Orange color for randomize button
    borderRadius: 8,
    margin: 10,
  },
  randomizeButtonText: {
    color: "#fff",
    textAlign: "center",
    fontWeight: "bold",
  },
  topButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 5, // Reduce margin
  },
  topButton: {
    padding: 5, // Reduce padding
    backgroundColor: "#e8e8e8",
    borderRadius: 6, // Adjust border radius
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginHorizontal: 5, // Reduce horizontal margin
  },
  loadingOverlay: {
  position: "absolute", // Make the spinner overlay absolute
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
  zIndex: 10, // Ensure it's on top
  elevation: 10, // Android shadow (for elevation support)
},
  scoreboardContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: 20,
    padding: 10,
    borderRadius: 15,
    backgroundColor: "#101820", // Dark background to match the image
    shadowColor: "#000",
    shadowOpacity: 0.3,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 8,
    elevation: 10, // Android shadow
  },
  teamContainer: {
    flex: 1,
    alignItems: "center",
    padding: 10,
    borderRadius: 10,
  },
  homeTeam: {
    backgroundColor: "#6a1b9a", // Purple-like color for home team
  },
  awayTeam: {
    backgroundColor: "#0288d1", // Blue-like color for away team
  },
  teamLogo: {
    width: 80,
    height: 80,
    marginBottom: 10,
  },
  teamName: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  scoreContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1e1e2f",
    paddingVertical: 10,
    paddingHorizontal: 25,
    borderRadius: 10,
  },
  scoreText: {
    color: "#fff",
    fontSize: 50,
    fontWeight: "bold",
    marginHorizontal: 10,
  },
  timerText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
    marginHorizontal: 10,
  },
 
  scoreTable: {
  borderWidth: 1,
  borderColor: "#ccc",
  borderRadius: 10,
  margin: 20,
  backgroundColor: "#27496D", // Dark background color

  // Existing shadow settings
  shadowColor: "#000",
  shadowOpacity: 0.3,
  shadowOffset: { width: 0, height: 2 }, 
  shadowRadius: 8,
  elevation: 10, // Android shadow

  // Add glow effect at the bottom
  shadowColor: "#4C7BEE", // Same color as your button for consistency
  shadowOffset: { width: 0, height: 6 }, // Strong shadow below the table
  shadowOpacity: 0.7, // Higher opacity for more visible glow
  shadowRadius: 5, // Larger radius for soft spread glow
  elevation: 15, // Ensure Android gets the effect too
},

  scoreTableRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  teamSection: {
    flexDirection: "column",
    alignItems: "center",
    marginHorizontal: 20,
  },
  homeLabel: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#4CAF50",
    textAlign: "center",
    marginBottom: 5,
  },
  awayLabel: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#FF5722",
    textAlign: "center",
    marginBottom: 5,
  },
  vsLabel: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
    marginHorizontal: 20,
  },
  scoreInput: {
    width: 100,
    height: 40,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 8,
    textAlign: "center",
    fontSize: 24,
    color: "#000",
    backgroundColor: "#f0f0f0",
  },

  saveButtonPressed: {
  backgroundColor: "#3B68CC", // Darker shade of green when pressed
  transform: [{ scale: 0.98 }], // Slightly shrink the button to give a pressed effect
},

saveButton: {
  marginTop: -1,
  paddingVertical: 5, // Extreme padding for a bigger button
  paddingHorizontal: 35, 
  backgroundColor: "#4C7BEE", // Vibrant color for the button itself
  borderRadius: 15, // More rounded corners for a bold look
  alignItems: "center",
  justifyContent: "center",
  
  // Extreme shadow settings
  shadowColor: "#000",
  shadowOffset: { width: 0, height: 12 }, // Very large vertical shadow
  shadowOpacity: 0.9, // Almost opaque shadow
  shadowRadius: 15, // Wide, soft shadow
  elevation: 15, // High elevation for a deeper shadow on Android

  // Optional border and glow for a futuristic effect
  borderColor: "#fff",
  borderWidth: 2,
  shadowColor: "#00FFFF", // Neon-like glow effect
  shadowOffset: { width: 0, height: 0 }, // Shadow on all sides
  shadowOpacity: 0.7, // Visible shadow-glow mix
  shadowRadius: 25,
},
saveButtonText: {
  color: "#fff",
  fontSize: 15, // Larger font size for impact
  fontWeight: "bold",
  textTransform: "uppercase", // Keep uppercase for emphasis
  letterSpacing: 2, // Spacing between letters for a strong effect
},


  homeInput: {
    backgroundColor: "#E8F5E9", // Light green for Home score input
  },
  awayInput: {
    backgroundColor: "#FFEBEE", // Light red for Away score input
  },
  sortButtonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
  },
  sortButton: {
    padding: 10,
    backgroundColor: "#e8e8e8",
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginHorizontal: 5,
  },

  sortButtonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  sortButtonActive: {
    backgroundColor: "#4C7BEE",
  },
  sortButtonInactive: {
    backgroundColor: "#ddd",
  },

  counterContainer: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: "#f9f9f9",
    alignItems: "center",
    justifyContent: "space-between", // Aligns items horizontally with equal spacing
  },
  counterText: {
    fontSize: 12,
    color: "#555", // Softer color for better readability
  },

});

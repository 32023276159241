import React, { memo, useEffect, useState } from "react";
import { View, Text, StyleSheet } from "react-native";
import { DrawerContentScrollView, DrawerItemList, DrawerItem } from "@react-navigation/drawer";
import { Avatar } from "react-native-elements";
import { signOut } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { database } from "../componenets/firebase";
import { getAuth } from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";

const auth = getAuth();

function useUserData(user) {
  const [displayName, setDisplayName] = useState("User");
  const [firstName, setFirstName] = useState("");
  const [Name, setName] = useState("");
  const [avatarUri, setAvatarUri] = useState(null);




  useEffect(() => {
    if (user) {
      const userid = user.uid;
     // console.log(`Current user ID: ${userid}`);
      const userRef = doc(database, "users", userid);

      const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
        const userData = docSnapshot.data();
        if (userData) {
          // setDisplayName(userData.displayName || "User");
          // setFirstName(userData.firstName || "");
          setName(userData.Name || "User");
          setAvatarUri(userData.avatarUri);
          
        } else {
          console.warn(`User2 ${userid} not found in the database`);
        }
      }, (error) => {
        console.error(error);
      });

      return () => {
        unsubscribe();
      };
    } else {
      console.warn("No user is currently logged in");
    }
  }, [user]);

  return { displayName, firstName, Name, avatarUri };
}

function CustomNavigationDrawer({ ...navProps }) {
  const [user] = useAuthState(auth);
  const { displayName, firstName, Name, avatarUri } = useUserData(user);

  return (
    <DrawerContentScrollView
      contentContainerStyle={styles.contentContainer}    >
      <View style={styles.header}>
      <Text style={styles.headerText}>{Name} </Text>        
        <Avatar
          rounded
          containerStyle={styles.avatarContainer}
          size="medium"
          source={{
            uri: avatarUri || "https://i.pravatar.cc/300",
            //uri: "https://i.pravatar.cc/300",
          }}
        />        
        {/* <Text style={styles.followersText}>My followers 11 (in testing)</Text> */}
      </View>
      <DrawerItemList {...navProps} />
      <View style={styles.logoutContainer}>
        <DrawerItem
          label="Logout"
          onPress={() => signOut(auth)}
          style={styles.logoutItem}
        />
      </View>
    </DrawerContentScrollView>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    backgroundColor: "#758bfd",
  },
  header: {
    padding: 10,
    backgroundColor: "#4361ee",
    height: 100,
  },
  headerText: {
    color: "white",
    fontSize: 20,
  },
  avatarContainer: {
    marginLeft: 5,
  },
  firstNameText: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white",
  },
  followersText: {
    fontSize: 11,
    color: "white",
  },
  logoutContainer: {
    flex: 1,
    marginVertical: 20,
    borderWidth: 1,
  },
  logoutItem: {
    flex: 1,
    justifyContent: "flex-end",
  },
});

export default memo(CustomNavigationDrawer);

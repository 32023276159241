import React, { useEffect, useState, useMemo } from 'react';
import { View, Text, FlatList, StyleSheet, Platform, Alert, Button, Pressable } from 'react-native';
import { collection, getDocs, doc, deleteDoc, setDoc } from 'firebase/firestore';
import { database } from '../componenets/firebase'; // Ensure this path is correct

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [sortField, setSortField] = useState('lastLogin'); // Default sort by 'lastLogin'
    const [sortDirection, setSortDirection] = useState('desc');

    // Function to add a hardcoded user document
    const userAddManual = async () => {
        try {
            // Hardcoded user data
            const userData = {
                CreatedAt: new Date(), // current timestamp
                email: 'google@test.com',
                name: 'Google',
                userId: 'iZEOlYOQwPalXJ4gXykKgCL8com1',
            };

            // Add a new document in the "users" collection with ID "123"
            const docRef = doc(collection(database, 'users'), 'iZEOlYOQwPalXJ4gXykKgCL8com1');
            await setDoc(docRef, userData);

            console.log('Document written with ID: userId');
        } catch (e) {
            console.error('Error adding document: ', e);
        }
    };

    useEffect(() => {
        const fetchUsersAndGames = async () => {
            try {
                // Fetch all users
                const usersCollectionRef = collection(database, "users");
                const userSnapshot = await getDocs(usersCollectionRef);
                let usersData = userSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    games: []
                }));

                // Sort users by creation date descending
                usersData.sort((a, b) => {
                    if (!a.createdAt && !b.createdAt) return 0; // both null, keep original order
                    if (!a.createdAt) return 1; // a is null, push to end
                    if (!b.createdAt) return -1; // b is null, push to end
                    const dateA = a.createdAt.toDate();
                    const dateB = b.createdAt.toDate();
                    return dateB - dateA; // sort descending
                });

                // Fetch all games
                const gamesCollectionRef = collection(database, "MainGame");
                const gamesSnapshot = await getDocs(gamesCollectionRef);

                gamesSnapshot.forEach(gameDoc => {
                    const gameData = gameDoc.data();
                    const gameId = gameDoc.id;
                    const gameName = gameData.name;
                    const memberOfGame = gameData.memberOfGame;

                    // Assign this game to all users who are members
                    usersData.forEach(user => {
                        if (memberOfGame && memberOfGame.some(member => member.uid === user.id)) {
                            user.games.push({
                                gameId: gameId,
                                name: gameName
                            });
                        }
                    });
                });

                setUsers(usersData); // Set users data after all fetching and processing is done
            } catch (error) {
                console.error("Failed to fetch users or games:", error);
            }
        };

        fetchUsersAndGames();
    }, []);

    // Memoized sorted users
    const sortedUsers = useMemo(() => {
        if (!sortField) return users; // Return original list if no sorting is applied

        return [...users].sort((a, b) => {
            let aValue = a[sortField];
            let bValue = b[sortField];
            if (!aValue && !bValue) return 0;
            if (!aValue) return 1;
            if (!bValue) return -1;
            if (aValue.toDate) aValue = aValue.toDate();
            if (bValue.toDate) bValue = bValue.toDate();
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
        });
    }, [users, sortField, sortDirection]); // Only recalculate when users, sortField, or sortDirection change

    const handleSort = (field) => {
        setSortField(field);
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    // Function to handle user deletion
    const deleteUser = (userId) => {
        // Platform-specific confirmation
        if (Platform.OS === "web") {
            if (window.confirm("Are you sure you want to delete this user? This action cannot be undone.")) {
                performDeletion(userId);
            }
        } else {
            Alert.alert(
                "Confirm Deletion",
                "Are you sure you want to delete this user? This action cannot be undone.",
                [
                    { text: "Cancel", onPress: () => console.log("Deletion cancelled"), style: "cancel" },
                    { text: "Delete", onPress: () => performDeletion(userId), style: "destructive" }
                ],
                { cancelable: false }
            );
        }
    };

    // Function to perform the actual deletion of the user
    const performDeletion = async (userId) => {
        const userDocRef = doc(database, "users", userId);
        try {
            await deleteDoc(userDocRef);
            setUsers(users.filter(user => user.id !== userId)); // Update local state after deletion
            console.log("User deleted successfully");
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    return (
        <View style={styles.container}>
            <Button title="Hardcode Add User" onPress={userAddManual} />

            <View style={styles.sortButtons}>
                <Pressable onPress={() => handleSort('name')} style={styles.sortButton}>
                    <Text>Sort by Name</Text>
                </Pressable>
                <Pressable onPress={() => handleSort('lastLogin')} style={styles.sortButton}>
                    <Text>Sort by Last Login</Text>
                </Pressable>
                <Pressable onPress={() => handleSort('createdAt')} style={styles.sortButton}>
                    <Text>Sort by Created At</Text>
                </Pressable>
                <Pressable onPress={() => handleSort('loginCount')} style={styles.sortButton}>
                    <Text>Sort by Login Count</Text>
                </Pressable>
            </View>

            <FlatList
                data={sortedUsers}
                keyExtractor={item => item.id}
                renderItem={({ item }) => (
                    <View style={styles.userItem}>
                        <Text style={styles.userInfo}>Name: {item.name}</Text>
                        <Text style={styles.userInfo}>Email: {item.email}</Text>
                        <Text style={styles.userInfo}>User ID: {item.userId}</Text>
                        <Text style={styles.userInfo}>Login Count: {item.loginCount}</Text>
                        <Text style={styles.userInfo}>
                            Last Login: {item.lastLogin ? item.lastLogin.toDate().toLocaleDateString('en-GB') : 'Unknown'}
                        </Text>
                        <Text style={styles.userInfo}>
                            Created At: {item.createdAt ? item.createdAt.toDate().toLocaleDateString('en-GB') : 'Unknown'}
                        </Text>
                        {item.games.length > 0 ? item.games.map(game => (
                            <Text key={game.gameId} style={styles.gameInfo}>
                                Game Name: {game.name}
                            </Text>
                        )) : <Text style={styles.gameInfo}>No games found for user.</Text>}
                        <Text onPress={() => deleteUser(item.id)} style={styles.deleteText}>Delete User</Text>
                    </View>
                )}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 20,
    },
    userItem: {
        padding: 10,
        marginVertical: 8,
        backgroundColor: '#f9f9f9',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    userInfo: {
        fontSize: 16,
        marginBottom: 5,
    },
    gameInfo: {
        fontSize: 14,
        color: 'blue',
    },
    deleteText: {
        color: 'red',
        marginTop: 10,
        fontWeight: 'bold',
    },
    sortButtons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: 10,
    },
    sortButton: {
        padding: 10,
        backgroundColor: '#ddd',
        borderRadius: 5,
    },
});

export default UserList;
